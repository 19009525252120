import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { addToCartById } from "@store/cart/slice";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { CartItem } from "@/common/types/types";
import { useTranslation } from "react-i18next";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import styles from "./styles.module.scss";
import { useFormik } from "formik";
import { FeaturedSliderSkeleton } from "./FeaturedSliderSkeleton";
import { useFetch } from "@/hooks/hooks";
import {
  subscribeInitialValues,
  subscribeSchema,
} from "@/common/validationSchemas/schemas";
import { ApiPath, ROOT } from "@/common/enums/apiPath";
import { getProductImage } from "@/helpers/helpers";
import { Sizes } from "../general/sizes/Sizes";
interface ProductCardProps {
  item: CartItem;
}

export const ApparelProductbox = (): React.ReactElement => {
  const [message, setMessage] = useState({ text: "", status: "" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart.cart);
  const [choseSize, setChoseSize] = useState<string>("");
  const [error, setError] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions

  const handleAddCart = (item: CartItem): void => {
    // navigate(productLink);
    if (item?.attributes?.size) {
      if (!choseSize) {
        setError(t("chooseSizeError"));
        return;
      } else {
        setError("");
      }
    }
    dispatch(
      addToCartById({
        id: item.id,
        count: 1,
        size: choseSize,
        price: item.attributes.price,
        sku: item.attributes.sku,
      }),
    );
  };

  const handleSizeClick = (s: string): void => {
    setError("");
    setChoseSize(s);
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cart));
  }, [cart]);

  const {
    data,
    loading,
    error: erro2,
  } = useFetch(`${ROOT}${ApiPath.ITEMSAPI}&filters[category][$eq]=Apparel`);

  const handleViewmore = (): void => {
    navigate("/category/Apparel");
  };

  return (
    <Box className={styles.section}>
      <div className="items-stretch bg-stone-50 flex flex-col pt-5 px-5 max-md:max-w-full mb-10">
        <div className="items-stretch flex justify-between gap-2.5 pt-2.5 max-md:max-w-full max-md:flex-wrap">
          <div className="text-stone-900 text-2xl font-semibold whitespace-nowrap max-md:max-w-full">
            Apparel
          </div>
          <div
            onClick={handleViewmore}
            className="text-white text-base font-semibold whitespace-nowrap justify-center items-stretch bg-neutral-700 px-6 py-1 rounded-[30px] self-start max-md:px-5    cursor-pointer transition-all duration-300 ease-in-out hover:bg-yellow-500"
          >
            View All
          </div>
        </div>
        <div className="items-stretch flex gap-2.5 mt-2.5 max-md:max-w-full max-md:flex-wrap max-md:justify-center pb-10">
          {data?.map((slide: CartItem, index: number) => (
            <div
              key={index}
              onClick={() => navigate(`/item/${slide?.attributes?.slug}`)}
              className="flex flex-col items-stretch max-md:w-full max-md:ml-0"
            >
              <div className="justify-center items-center shadow-sm bg-white flex grow basis-[0%] flex-col pt-2.5 pb-5 px-2.5">
                <img
                  loading="lazy"
                  src={getProductImage(slide.attributes.image)}
                  alt=""
                  // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/888c0ce26d71e61bfd204ad4f2a5a36fcc5e5496a7226fabc5aa230090158f1e?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                  className="aspect-[1.3] object-contain object-center w-48 overflow-hidden   transition-transform duration-300 transform hover:scale-110"
                />
                <div className="self-stretch text-black text-base leading-5 mt-2 text-left">
                  Mystic 2019 Majestic Quickdry Longsleeve
                </div>
                <div className="text-black text-sm font-bold self-stretch whitespace-nowrap mt-5 text-left">
                  AED 347.00
                </div>
              </div>
            </div>
          ))}
        </div>{" "}
      </div>
    </Box>
  );
};
