import {
  Box,
  Container,
  Typography,
  Grid,
  Chip,
  Pagination,
} from "@mui/material";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ApiPath, ROOT } from "@enums/apiPath";
import { ProductCard } from "@components/productCard/ProductCard";
import { Filters } from "@/components/filters/Filters";
import { useFetch, useUrlParams } from "@/hooks/hooks";
import { useTranslation } from "react-i18next";
import { Loader } from "@components/general/loader/Loader";
import { CartItem, EventItem } from "@/common/types/types";

import styles from "./styles.module.scss";
import { ProductCard2 } from "@/components/productCard/ProductCard2";
import { getProductImage } from "@/helpers/helpers";
import { BlogItem } from "@/common/types/blog/blog";

export const BlogDetail = (): React.ReactElement => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  // Access the 'item' data from the previous route
  const item: BlogItem = state?.item;

  const handleGoBack = (): void => {
    navigate(-1);
  };

  const processDate = (dval: string): string => {
    const dateObject = new Date(dval);

    // Check if the date is valid
    if (!isNaN(dateObject.getTime())) {
      // Format the date as a string (e.g., "2023-01-01 12:00 PM")
      const formattedDateString = dateObject.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      return formattedDateString;
    } else {
      // Handle invalid date string
      // console.error('Invalid date-time string:', dateTimeString);
      return "Invalid Date'";
    }
  };

  return (
    <div className="items-center self-stretch bg-slate-50 flex flex-col justify-center px-16 py-12 max-md:px-5 rounded-2xl overflow-hidden">
      <div className="flex w-full max-w-[1140px] flex-col items-stretch my-8 max-md:max-w-full rounded-2xl overflow-hidden">
        <img
          // loading="eager"
          src={getProductImage(item.attributes.image)}
          alt={item.attributes.title}
          className="aspect-[3.06] object-cover object-center w-full justify-end items-start overflow-hidden max-md:max-w-full"
        />
        <div className="items-start bg-white flex flex-col pt-5 pb-10 px-8 rounded-none max-md:max-w-full max-md:px-5">
          <div className="self-stretch text-neutral-800 text-2xl font-semibold whitespace-nowrap max-md:max-w-full">
            {item.attributes.title}
          </div>
          <div className="self-stretch text-neutral-800 text-lg mt-4 max-md:max-w-full">
            {item.attributes.description}
          </div>
          <div className="self-stretch mt-4 py-11 max-md:max-w-full max-md:pr-5">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
              <div className="flex flex-col items-stretch w-[55%] max-md:w-full max-md:ml-0">
                <div className="items-stretch flex justify-between gap-2.5 max-md:mt-10">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d34b5bf4b5bcf752fe7979fad06b976efb00c2b327f9eb0d93c3ac982cd14e5?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                    className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                  />
                  <div className="text-stone-900 text-lg font-semibold self-center grow whitespace-nowrap my-auto">
                    {processDate(item.attributes.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-stretch w-[45%] ml-5 max-md:w-full max-md:ml-0">
                <div className="items-stretch flex justify-between gap-2.5 max-md:mt-10">
                  {/* <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/477aac9578a3263e4d277def20a17c9f26dca62bcf9c634112d9a7bf465505ca?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
                    className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full"
                  /> */}
                  {/* <div className="text-stone-900 text-lg font-semibold self-center grow whitespace-nowrap my-auto">
                    Username
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="items-stretch bg-neutral-600 flex gap-5 mt-4 pl-4 pr-8 py-1.5 rounded-3xl self-start max-md:pr-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1293b074f50d14aafdbc98595b8bb2ba26667281239af29d2be50881616ad69d?apiKey=7a9cb5296d3a4c3cb0abf4497ed9db88&"
              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
            />
            <div
              onClick={() => {
                handleGoBack();
              }}
              className="text-white text-base font-semibold self-start"
              style={{ cursor: "pointer" }}
            >
              Back to list
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
